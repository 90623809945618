import React from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../../components/Layout/Layout"
import Template from "../../components/Template/Template"
import { renderPageNode } from "../../utilities/renderPageNode"
import { renderTags } from "../../utilities/renderTags"
import { getBlogPagePath } from "../../utilities/blogUtilities"
import t from "../../translations"
import SEO from "../../components/seo"
import {
  doesTagsContainNoIndex,
  getHrefLangTagProps,
  getLocaleLanguageCode,
  localizedPath
} from "../../utilities/utilities"
import Pager from "../../components/Pager/Pager"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import BlogHead from "./BlogHead"
import CategorySection from "./CategorySection"
import ArticleGrid from "../../components/ArticleGrid/ArticleGrid"
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb"

import styles from "./Blog.module.scss"
import getPageData from "../../analytics/getPageData"
import { useScrollTracking } from "../../analytics/useScrollTracking"
import { usePageTracking } from "../../analytics/usePageTracking"

export const query = graphql`
  query (
    $pageId: String!
    $articleStartIndex: Int!
    $itemsPerPage: Int!
    $locale: String!
  ) {
    placeholder: file(
      relativePath: { eq: "placeholder-missingblogimage.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 260, height: 200, layout: CONSTRAINED)
      }
    }
    contentfulPage(id: { eq: $pageId }) {
      ...pageFields
    }
    site {
      siteMetadata {
        defaultLocale
        siteUrl
      }
    }
    allContentfulBlogPost(
      sort: { fields: [datePublished], order: [DESC] }
      skip: $articleStartIndex
      limit: $itemsPerPage
      filter: {
        node_locale: { eq: $locale }
        datePublished: { ne: null }
        slug: { ne: null }
      }
    ) {
      nodes {
        id
        title
        ...blogPostShortFields
        dateCreated(formatString: "dd MMM yyyy", locale: $locale)
        datePublished(formatString: "dd MMM yyyy", locale: $locale)
        thumbnail: mainImage {
          description
          gatsbyImageData(
            layout: CONSTRAINED
            width: 512
            aspectRatio: 1.3
            resizingBehavior: FILL
            quality: 100
          )
        }
      }
    }
  }
`

const Blog = props => {
  useScrollTracking()
  usePageTracking()
  const {
    localeLinks,
    pageIndex,
    // totalArticles,
    totalPages,
    locale,
    defaultLocale
  } = props.pageContext
  const articles = props.data.allContentfulBlogPost.nodes
  const page = props.data.contentfulPage
  const currentPage = pageIndex + 1
  const options = {
    renderNode: renderPageNode(
      locale,
      props.data.site.siteMetadata.defaultLocale
    )
  }

  const breadcrumb = [
    {
      id: localizedPath("", null, locale, defaultLocale),
      title: t.translate(t.KEYS.HOME, locale),
      url: localizedPath("", null, locale, defaultLocale)
    },
    {
      id: localizedPath("blog", null, locale, defaultLocale),
      title: "Blog",
      url: null
    }
  ]

  function renderBody() {
    if (pageIndex === 0 && page.pageBody) {
      return renderRichText(page.pageBody, options)
    }

    return null
  }

  const replacePaginationValues = string => {
    return string
      ?.replace("{{current_page}}", currentPage)
      ?.replace("{{total_pages}}", totalPages)
  }

  const pageStartScriptTags = renderTags(
    locale,
    defaultLocale,
    page.bodyStartScripts
  )
  const pageEndScriptTags = renderTags(
    locale,
    defaultLocale,
    page.bodyEndScripts
  )
  const hasFixedMenu = _.defaultTo(_.get(page, "fixedMenu"), true)

  const htmlLang = locale.split("-")[0]
  const title = replacePaginationValues(_.get(page, "title", ""))
  const metaDescription = replacePaginationValues(
    _.get(page, "description.description", "")
  )
  const pageUrl = `${props.data.site.siteMetadata.siteUrl}${_.get(
    props,
    "pageContext.pageUrl",
    ""
  )}`
  const languageCode = getLocaleLanguageCode(locale)

  const linkGenerator = pageNumber => {
    return getBlogPagePath(pageNumber, locale, defaultLocale)
  }

  const pageData = getPageData(
    locale,
    {
      pagePerimeter: _.get(page, "pagePerimeter", ""),
      pagePublication: page.segmentUpdatedAt,
      pageFeatureSector: _.get(page, "pageFeatureSector", "")
    },
    page.slug,
    props.path
  )
  const doesPageContainsNoIndexTag =
    doesTagsContainNoIndex(page.extraTags) ||
    (props.data.contentfulPage.template &&
      props.data.contentfulPage.template.headTags &&
      doesTagsContainNoIndex(props.data.contentfulPage.template.headTags))

  function renderHrefLangTags() {
    return getHrefLangTagProps(
      localeLinks,
      props.data.site.siteMetadata.siteUrl
    ).map(tagProps => {
      return <link {...tagProps} key={tagProps.key} />
    })
  }

  return (
    <Layout locale={locale} defaultLocale={props.pageContext.defaultLocale}>
      <SEO
        description={metaDescription}
        lang={htmlLang}
        title={title}
        url={pageUrl}
        includeSelfCanonical={!doesPageContainsNoIndexTag}
      />
      <Helmet>
        <script type="text/javascript">{`${pageData}`}</script>
        {currentPage < totalPages && (
          <link
            rel="next"
            href={getBlogPagePath(currentPage + 1, locale, defaultLocale)}
          />
        )}
        {currentPage > 1 && (
          <link
            rel="prev"
            href={getBlogPagePath(currentPage - 1, locale, defaultLocale)}
          />
        )}
        {renderTags(
          locale,
          props.data.site.siteMetadata.defaultLocale,
          page.extraTags
        )}
        {props.data.contentfulPage.template &&
          props.data.contentfulPage.template.headTags &&
          renderTags(
            locale,
            props.pageContext.defaultLocale,
            props.data.contentfulPage.template.headTags
          )}
        {renderHrefLangTags()}
      </Helmet>
      <Template
        {...page.template}
        showTopBanner={languageCode === "de"}
        isMenuFixed={hasFixedMenu}
        localeLinks={localeLinks}
        locale={props.pageContext.locale}
        defaultLocale={props.pageContext.defaultLocale}
      >
        {pageStartScriptTags}
        <div className={styles.blog}>
          <Breadcrumb links={breadcrumb} />
          <BlogHead className="my-4" locale={locale} />
          {renderBody()}
          <CategorySection locale={locale} defaultLocale={defaultLocale} />
          <ArticleGrid
            showNewsletterBlock={currentPage === 1}
            title={t.translate(t.KEYS.LATEST_ARTICLES, locale)}
            articles={articles}
            locale={locale}
            defaultLocale={defaultLocale}
            footer={
              <Pager
                totalPages={totalPages}
                currentPage={currentPage}
                linkGenerator={linkGenerator}
              />
            }
          />
        </div>
        {pageEndScriptTags}
      </Template>
    </Layout>
  )
}

Blog.propTypes = {
  path: PropTypes.string.isRequired,
  data: PropTypes.object,
  pageContext: PropTypes.object
}

export default Blog
